// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.border-5 {
    border-width:5px !important;
}

.modal-90w {
	width:90%;
	max-width:none !important
}

.modal-50w {
	width:50%;
	max-width:none !important
}

.english-mark {
	color: purple ;
	font-weight: bold ;
}

.texteSyllabus {
	font-family: Georgia, 'Times New Roman', Times, serif;
	line-height: 1.4;
	font-size: 0.9rem;
}

.texteSyllabus h1,
.texteSyllabus h2,
.texteSyllabus h3,
.texteSyllabus h4,
.texteSyllabus h5,
.texteSyllabus h6 {
  margin-top: 0;
  margin-bottom: 0.4rem;
  line-height: 1.2;
}

.texteSyllabus p {
	margin-top: 0;
	margin-bottom: 0.8rem;
  }

.texteSyllabus h1,
  .texteSyllabus .h1 {
	font-size: 1.37rem;
  }

.texteSyllabus h2,
  .texteSyllabus .h2 {
	font-size: 1.2rem;
  }

.texteSyllabus h3,
  .texteSyllabus .h3 {
	font-size: 1.035rem;
	font-weight: bold;
  }

.texteSyllabus h4,
  .texteSyllabus .h4 {
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: bold;
  }

.texteSyllabus h5,
  .texteSyllabus .h5 {
	font-size: 0.9rem;
	font-style: italic;
	font-weight: bold;
  }

.texteSyllabus h6,
  .texteSyllabus .h6 {
	font-size: 0.9rem;
	font-style: italic;
  }

.btnSyllabus:hover {
	background: gainsboro;
	border-radius: 4px;
}
